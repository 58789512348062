import React, { useContext } from "react";
import Layout from "@components/layouts/Layout";
import Seo from "@components/common/Seo";
import SmallBanner from "@components/common/SmallBanner";
import Testimonials from "@components/sections/LazyTestimonials";
import { navigate } from "gatsby";

import firebase from "gatsby-plugin-firebase";
import { CartContext } from "@components/contexts/CartContext";
import useSound from "use-sound";
import addToBasketSfx from "@static/sounds/addProduct.mp3";
import { MdAddShoppingCart } from "react-icons/md";
import Title from "@components/common/Title";
// import Text from "@components/common/Text";
import { trackFbAddToCart } from "@base/utils/fb";
import axios from "axios";
import useSWR from "swr";

// import items from "@base/data/products.js";
/*const productos = require("../../../src/data/Products").default.filter(
    p => p.enabled
);*/

const endpoints = {
    products: "/.netlify/functions/products",
};

const ProductTemplate = ({ item: a, children, counter }) => {
    const { addProduct, clearCart } = useContext(CartContext);
    const [play] = useSound(addToBasketSfx, { volume: 0.05 });
    const { data: result } = useSWR(endpoints.products, axios.get);

    const item = result ? result?.data?.products?.find(p => p.id === a.id) : null;

    return (
        <>
            {!item ? (
                <>Cargando...</>
            ) : (
                <Layout>
                    <Seo title={`${item.title}`} description={`${item.description}`} />
                    <SmallBanner
                        title={item.title}
                        subtitle={item.description}
                        // filename={item.banner}
                        filename={"bgs/bgGenericNew"}
                    />

                    <div>
                        <div>{children}</div>
                    </div>

                    {item.stock && item.enabled && !item.special_buy && (
                        <div className="container lg:px-0 pb-20">
                            <Title>Comprar producto</Title>
                            <div>
                                <button
                                    title="Comprar producto"
                                    className={`flex justify-center w-full px-5 py-5 bg-ccyan-dark rounded shadow text-white gap-5 uppercase font-montserrat`}
                                    disabled={!item.stock}
                                    onClick={async () => {
                                        clearCart();
                                        addProduct(item);
                                        play();

                                        await trackFbAddToCart({
                                            currency: "EUR",
                                            value: item.price,
                                        });

                                        firebase.analytics().logEvent("add_to_cart", {
                                            item_id: item.sku,
                                            item_name: item.title,
                                            item_category: item.type,
                                            price: item.price,
                                            currency: "EUR",
                                            quantity: 1,
                                        });

                                        navigate("/cesta");
                                    }}
                                >
                                    <MdAddShoppingCart
                                        className="w-6 h-6 inline"
                                        style={{ verticalAlign: "middle" }}
                                    />
                                    Comprar producto
                                </button>
                            </div>
                        </div>
                    )}

                    <Testimonials
                        className={`${counter % 2 === 0 ? "" : "bg-gray-100 bg-opacity-50"}`}
                        uid={item.sku}
                    />
                </Layout>
            )}
        </>
    );
};

export default ProductTemplate;
